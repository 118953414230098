export interface Dictionary<T> {
  [key: string]: T;
}

export interface Entry<T> {
  key: string;
  value: T;
}

/** Unpack a dictionary into a list of entries */
export function entries<T>(dictionary: Dictionary<T>): Entry<T>[] {
  const result: Entry<T>[] = [];
  for (const key in dictionary) {
    result.push({
      key: key,
      value: dictionary[key],
    });
  }
  return result;
}

export interface ConflictResolver<T> {
  (a: Entry<T>, b: Entry<T>): Entry<T>;
}
export const lastValueWins = <T>(a: Entry<T>, b: Entry<T>) => b;

/** Construct a dictionary using a list of entries */
export function dict<T>(
  entryList: Entry<T>[],
  resolveCollision: ConflictResolver<T> = lastValueWins,
): Dictionary<T> {
  const result: Dictionary<T> = {};
  for (let entry of entryList) {
    // If there is a value already under this key, resolve the conflict
    if (result[entry.key] !== undefined) {
      entry = resolveCollision(
        {
          key: entry.key,
          value: result[entry.key],
        },
        entry,
      );
    }
    result[entry.key] = entry.value;
  }
  return result;
}

/** Construct a dictionary where each key has multiple values */
export function multiDict<T>(entryList: Entry<T>[]): Dictionary<T[]> {
  const singletons = entryList.map(({ key, value }) => ({ key, value: [value] }));
  return dict(singletons, (a, b) => {
    return {
      key: a.key,
      value: a.value.concat(b.value),
    };
  });
}

/** Unpack a multiDict into a list of entries */
export function multiDictEntries<T>(input: Dictionary<T[]>): Entry<T>[] {
  const result: Entry<T>[] = [];
  for (const key in input) {
    for (const value of input[key]) {
      result.push({
        key,
        value,
      });
    }
  }
  return result;
}

/** Map over the entries of a dictionary, producing a new dictionary */
export function dictMap<I, O>(
  input: Dictionary<I>,
  fn: (entry: Entry<I>) => Entry<O>,
): Dictionary<O> {
  return dict(entries(input).map(fn));
}

/* tslint:disable-next-line:variable-name */
export const _dictMap = <I, O>(fn: (entry: Entry<I>) => Entry<O>) => (input: Dictionary<I>) =>
  dictMap(input, fn);

/** Map over the values of a dictionary, producing a new one with the same keys */
export function dictMapValues<I, O>(
  input: Dictionary<I>,
  fn: (value: I, key: string) => O,
): Dictionary<O> {
  return dictMap(input, ({ key, value }) => ({ key, value: fn(value, key) }));
}

/* tslint:disable-next-line:variable-name */
export const _dictMapValues = <I, O>(fn: (value: I, key: string) => O) => (input: Dictionary<I>) =>
  dictMapValues(input, fn);
