import * as React from 'react';
import classNames from 'classnames';
import styles from './Textarea.module.scss';

export interface TextareaConfig {
  className?: string;
  autoFocus?: boolean;
  placeholder?: string;
  block?: boolean;
}

interface OwnProps extends TextareaConfig {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  error?: boolean;
  message?: string;
}

export const Textarea = (props: OwnProps) => {
  return (
    <div
      className={classNames(
        styles.Textarea,
        {
          [styles.Block]: props.block,
          [styles.Error]: props.error,
        },
        props.className,
      )}
    >
      <textarea
        className={styles.InputElement}
        onChange={(e) => props.onChange(e.target.value)}
        autoFocus={props.autoFocus}
        disabled={props.disabled}
        placeholder={props.placeholder}
        value={props.value}
      />
      {props.message ? <div className={styles.InputMessage}>{props.message}</div> : null}
    </div>
  );
};
