import * as React from 'react';
import classNames from 'classnames';
import styles from './StarRating.module.scss';

interface OwnProps {
  rating: number;
  onChange: (value: number) => void;
}

export const StarRating = (props: OwnProps) => {
  const [rating, setRating] = React.useState<number | null>(null);

  const displayRating = rating !== null ? rating : props.rating;

  return (
    <div
      className={classNames(styles.StarRating, {
        [styles.Medium]: displayRating >= 3,
        [styles.Good]: displayRating >= 4,
      })}
    >
      <ul className={styles.Stars}>
        {arrayOfSize(5).map((i) => (
          <li
            key={i}
            className={classNames({
              [styles.Inactive]: i + 1 > displayRating,
            })}
            onMouseEnter={() => setRating(i + 1)}
            onMouseLeave={() => setRating(null)}
            onClick={() => props.onChange(i + 1)}
          >
            <a className={styles.Card} onClick={() => props.onChange(i + 1)}>
              <i className='fas fa-star' />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

function arrayOfSize(s: number): number[] {
  const arr = [];

  for (let i = 0; i < s; i++) {
    arr.push(i);
  }

  return arr;
}
