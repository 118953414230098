import * as React from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import Head from '../../components/Head';
import { parse } from 'qs';
import { PageRendererProps } from 'gatsby';
import logoFull from '../../../assets/images/logo_full_purple.png';
import { TextInput } from '../../components/TextInput';
import { Button, ButtonStyle } from '../../components/Button';
import { Textarea } from '../../components/Textarea';
import { StarRating } from '../../components/StarRating';
import { isMissing, notMissing } from '../../lib/typeGuards';
import { Alert, AlertType } from '../../components/Alert';
import {
  ReviewFormMetadata,
  apiClient,
  isResponseError,
  extractMessageAndPath,
  ReviewState,
  MetadataFieldType,
} from '../../api/APIClient';
import { Dropdown } from '../../components/Dropdown';
import { dict } from '../../lib/Dictionary';
import { every, reverse, sort, _compare } from '../../lib/list';
import { identity } from '../../lib/function';

const ReviewPage: React.FunctionComponent<PageRendererProps> = ({ location }) => {
  const query = parse(location.search.substr(1));
  // const isFrame = query.frame === 'true';
  const locationId = query.lid as string | undefined;
  let token = query.token as string | undefined;

  const [formMetadata, setFormMetadata] = React.useState<ReviewFormMetadata | null>(null);
  const [formError, setFormError] = React.useState<string | null>(null);
  const [completed, setCompleted] = React.useState(false);

  const pageContainer = (content: any) => (
    <div className={styles.ReviewPage}>
      <Head title='Opinous | Write a Review' />
      <div className={styles.Content}>
        <h1>Let us know what you think!</h1>
        {content}
      </div>
      <div className={styles.Footer}>
        <div>verified by</div>
        <a href='https://opinous.com' target='_blank'>
          <img src={logoFull} />
        </a>
      </div>
    </div>
  );

  if (isMissing(locationId)) {
    return pageContainer(null);
  }

  if (locationId.length !== 36) {
    return pageContainer(
      <Alert type={AlertType.Error}>The Location ID provided doesn't look valid</Alert>,
    );
  }

  if (notMissing(token) && token.length !== 36) {
    token = undefined; // If the token is malformed just allow the user to submit a normal review
  }

  React.useEffect(() => {
    apiClient
      .getReviewFormMetadata(locationId, token)
      .then((meta) => {
        const sorted = reverse(sort(meta.metadata_fields, _compare(({ priority }) => priority)));
        setFormMetadata({
          ...meta,
          metadata_fields: sorted,
        });
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          setFormError('Invalid Location ID provided');
        } else if (isResponseError(e)) {
          const [message] = extractMessageAndPath(e);
          setFormError(message);
        } else {
          setFormError('Could not load review form');
        }
      });
  }, []);

  if (notMissing(formError)) {
    return pageContainer(<Alert type={AlertType.Error}>{formError}</Alert>);
  }

  if (isMissing(formMetadata)) {
    return pageContainer(
      <div className={styles.Loading}>
        <i className='far fa-sync-alt fa-spin' />
        <p>Fetching the review form, just a sec!</p>
      </div>,
    );
  }

  if (notMissing(formMetadata.review_state) && formMetadata.review_state !== ReviewState.INVITED) {
    return pageContainer(
      <Alert type={AlertType.Info}>Your review has already been submitted</Alert>,
    );
  }

  if (completed) {
    return pageContainer(
      <div className={styles.Completed}>
        <i className='far fa-check' />
        <p>
          {notMissing(token)
            ? 'Thanks for your feedback!'
            : "Thanks for your feedback! We've sent you an email to confirm your identity."}
        </p>
      </div>,
    );
  }

  return pageContainer(
    <ReviewForm
      locationId={locationId}
      formMeta={formMetadata}
      token={token}
      onComplete={() => setCompleted(true)}
    />,
  );
};

interface FormError {
  message: string;
  field: string | null;
}

interface FormProps {
  locationId: string;
  formMeta: ReviewFormMetadata;
  onComplete: () => void;
  token?: string | undefined;
}

const ReviewForm: React.FunctionComponent<FormProps> = (props) => {
  const { locationId, formMeta, token, onComplete } = props;
  const validInvite = formMeta.review_state === ReviewState.INVITED;

  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState<FormError | null>(null);
  const [rating, setRating] = React.useState(0);
  const [body, setBody] = React.useState('');
  const [metadata, setMetadata] = React.useState(
    dict(
      formMeta.metadata_fields.map((field) => ({
        key: field.id,
        value: '',
      })),
    ),
  );
  const [name, setName] = React.useState(
    validInvite && formMeta.reviewer_name ? formMeta.reviewer_name : '',
  );
  const [email, setEmail] = React.useState(validInvite ? 'invited@opinous.com' : '');

  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitting(true);
    setError(null);

    try {
      const base = {
        full_name: name,
        review_body: body,
        overall_rating: rating,
        metadata,
      };

      await (validInvite
        ? apiClient.completeReview(locationId, { ...base, token: token! })
        : apiClient.submitReview(locationId, { ...base, email_address: email }));

      onComplete();
    } catch (e) {
      if (isResponseError(e)) {
        const [message, path] = extractMessageAndPath(e);

        const inputs = [
          'email_address',
          'full_name',
          'review_body',
          ...formMeta.metadata_fields.map(({ id }) => `metadata.${id}`),
        ];

        setError({ message, field: hasInput(inputs, path) });
      } else {
        setError({ message: 'Unexpected error occured', field: null });
      }
      setSubmitting(false);
    }
  };

  const allMetadataFieldsHaveValid = every(
    formMeta.metadata_fields.map(
      (field) => !field.is_required || (field.is_required && metadata[field.id].length > 0),
    ),
    identity,
  );

  const bodyAllowed = rating !== 0;
  const infoAllowed = bodyAllowed && body.length > 0 && allMetadataFieldsHaveValid;
  const allowSubmit =
    rating !== 0 && body.length > 0 && name.length > 0 && email.length > 0 && !submitting;

  return (
    <form onSubmit={submitForm}>
      {/* <p>
        Your feedback is extremely important to us and we appreciate you have taken the time to
        review your experience. We have partnered with Opinous, an independant blabasd fad, to
        provide this service to ensure that all our reviews are verified and credible.
      </p> */}
      <section>
        <h2>
          Overall Rating <i>*</i>
        </h2>
        <StarRating onChange={setRating} rating={rating} />
      </section>
      <section className={classNames({ [styles.Blocked]: !bodyAllowed })}>
        <h2>
          Tell us about your experience with {formMeta.location_name}? <i>*</i>
        </h2>
        <h3>Help future customers by mentioning the high, lows and superstars!</h3>
        <Textarea
          onChange={setBody}
          value={body}
          block={true}
          error={notMissing(error) && error.field === 'review_body'}
          message={notMissing(error) && error.field === 'review_body' ? error.message : undefined}
        />
      </section>
      {formMeta.metadata_fields.map((field) => {
        let fieldElement: JSX.Element;

        switch (field.field_type) {
          case MetadataFieldType.DROPDOWN:
            fieldElement = (
              <Dropdown
                onChange={(nv) => setMetadata({ ...metadata, [field.id]: nv })}
                value={metadata[field.id]}
                block={true}
                placeholder={field.field_placeholder || 'Select an option'}
                options={field.field_values!.map((value) => ({ text: value, value }))}
                error={notMissing(error) && error.field === `metadata.${field.id}`}
                message={
                  notMissing(error) && error.field === `metadata.${field.id}`
                    ? error.message
                    : undefined
                }
              />
            );
            break;
          case MetadataFieldType.TEXT:
            fieldElement = (
              <TextInput
                onChange={(nv) => setMetadata({ ...metadata, [field.id]: nv })}
                value={metadata[field.id]}
                block={true}
                placeholder={field.field_placeholder || undefined}
                error={notMissing(error) && error.field === `metadata.${field.id}`}
                message={
                  notMissing(error) && error.field === `metadata.${field.id}`
                    ? error.message
                    : undefined
                }
              />
            );
            break;
          case MetadataFieldType.RATING:
            fieldElement = (
              <div className={styles.AdditionalRating}>
                <StarRating
                  onChange={(nv) => setMetadata({ ...metadata, [field.id]: String(nv) })}
                  rating={Number(metadata[field.id].length > 0 ? metadata[field.id] : '0')}
                />
              </div>
            );
            break;
          default:
            fieldElement = <div>Unsupported field type</div>;
            break;
        }

        return (
          <section key={field.id} className={classNames({ [styles.Blocked]: !bodyAllowed })}>
            <h2>
              {field.field_title} {field.is_required ? <i>*</i> : null}
            </h2>
            {notMissing(field.field_sub_title) && field.field_sub_title.length > 0 ? (
              <h3>{field.field_sub_title}</h3>
            ) : null}
            {fieldElement}
          </section>
        );
      })}
      <section className={classNames({ [styles.Blocked]: !infoAllowed })}>
        <h2>
          What's your name? <i>*</i>
        </h2>
        <h3>
          This will be published alongside your review, so feel free to just put your first name.
        </h3>
        <TextInput
          onChange={setName}
          autoComplete='name'
          value={name}
          block={true}
          error={notMissing(error) && error.field === 'full_name'}
          message={notMissing(error) && error.field === 'full_name' ? error.message : undefined}
        />
      </section>
      {!validInvite ? (
        <section className={classNames({ [styles.Blocked]: !infoAllowed })}>
          <h2>
            What's your email? <i>*</i>
          </h2>
          <h3>
            This will be used to verify that your review is genuine, and will <b>not</b> be
            published.
          </h3>
          <TextInput
            onChange={setEmail}
            autoComplete='email'
            value={email}
            block={true}
            error={notMissing(error) && error.field === 'email_address'}
            message={
              notMissing(error) && error.field === 'email_address' ? error.message : undefined
            }
          />
        </section>
      ) : null}
      <section className={classNames(styles.Submit, { [styles.Blocked]: !allowSubmit })}>
        <Button style={ButtonStyle.Primary} disabled={!allowSubmit}>
          Submit Review
        </Button>
      </section>
    </form>
  );
};

export default ReviewPage;

function hasInput(inputs: string[], path: string | undefined): string | null {
  if (notMissing(path) && path.substr(0, 5) === 'body.') {
    const input = path.substr(5);
    if (inputs.indexOf(input) >= 0) {
      return input;
    }
  }

  return null;
}
