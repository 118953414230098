import * as React from 'react';
import classNames from 'classnames';
import styles from './Button.module.scss';

export enum ButtonStyle {
  Primary = 'primary',
  Secondary = 'secondary',
  Green = 'green',
  Orange = 'orange',
  Red = 'red',
  Danger = 'danger',
  White = 'white',
}

interface OwnProps {
  children: any;
  className?: string;
  style?: ButtonStyle;
  disabled?: boolean;
  block?: boolean;
  onClick?: () => void;
}

export const Button = (props: OwnProps) => {
  return (
    <button
      onClick={props.onClick}
      className={classNames(
        styles.Button,
        {
          [styles['Button--block']]: props.block,
        },
        props.className,
      )}
      disabled={props.disabled}
      data-style={props.style}
    >
      {props.children}
    </button>
  );
};
