import { Dictionary, Entry, dict } from './Dictionary';
import { notMissing, TypeGuard } from './typeGuards';

/** A 1-ary function with input type I and output O. */
export type Func<I, O> = (input: I) => O;
/** A 1-ary function with input T and a boolean output. */
export type Predicate<T> = Func<T, boolean>;

/*
 * Functions to aid in working with lists,
 * those beginning with _ are curried with the function argument first
 */

/** Used for flattening 2D arrays into 1D arrays */
export function flatten<T>(input: T[][]): T[] {
  const result: T[] = [];
  for (const nested of input) {
    for (const value of nested) {
      result.push(value);
    }
  }
  return result;
}

/** A mixed recursive data structure consisting of arrays and elements of type T. */
export interface DeeplyNested<T> extends Array<T | DeeplyNested<T>> {}

/** Flatten a mixed / deeply nested structure to a simple array. */
export function deepFlatten<T>(a: DeeplyNested<T>): T[] {
  let result: T[] = [];
  for (const item of a) {
    if (item instanceof Array) {
      result = result.concat(deepFlatten(item));
    } else {
      result = result.concat([item]);
    }
  }
  return result;
}

/** Removes null and undefined values from array */
export function compact<T>(input: (T | null | undefined)[]): T[] {
  return filter(input, notMissing);
}

/** Apply a function to every element of a list to construct a new list. */
export function map<I, O>(arr: I[], func: Func<I, O>): O[] {
  return arr.map(func);
}

export function _map<I, O>(func: Func<I, O>): (arr: I[]) => O[] {
  return (arr) => arr.map(func);
}

/**
 * Remove the first occurrence of an element from a list (strict equality),
 * producing a new list.
 */
export function remove<T>(arr: T[], value: T): T[] {
  const index = arr.indexOf(value);
  if (index === -1) {
    return arr;
  }
  arr = [...arr]; // Create a copy
  arr.splice(index, 1); // Remove item
  return arr;
}

export function _remove<T>(value: T): (arr: T[]) => T[] {
  return (arr) => remove(arr, value);
}

/** Add a new element to the end of a list. */
export function append<T>(arr: T[], value: T): T[] {
  return concat(arr, [value]);
}

export function _append<T>(value: T): (arr: T[]) => T[] {
  return _concat([value]);
}

/** Join two lists together. */
export function concat<T>(arrOne: T[], arrTwo: T[]): T[] {
  return arrOne.concat(arrTwo);
}

export function _concat<T>(vs: T[]): (arr: T[]) => T[] {
  return (arr) => arr.concat(vs);
}

// Returns the last index that matches a predicate
// Used internally by dropWhile and takeWhile
function advanceWhile<T>(arr: T[], func: Predicate<T>): number {
  let index = 0;
  const length = arr.length;
  while (index < length && func(arr[index])) {
    index++;
  }
  return index;
}

/** Remove items from the front of an array while the predicate is true. */
export function dropWhile<T>(arr: T[], func: Predicate<T>): T[] {
  return arr.slice(advanceWhile(arr, func), arr.length);
}

/** Keep items at the front of an array while while the predicate is true, discard the rest. */
export function takeWhile<T>(arr: T[], func: Predicate<T>): T[] {
  return arr.slice(0, advanceWhile(arr, func));
}

export function filter<T, S extends T>(arr: T[], func: TypeGuard<T, S>): S[];
export function filter<T>(arr: T[], func: Predicate<T>): T[];
/** Remove all items in the array for which the predicate returns false. */
export function filter<T>(arr: T[], func: Predicate<T>): T[] {
  return arr.filter(func);
}

export function _filter<T, S extends T>(func: TypeGuard<T, S>): (arr: T[]) => S[];
export function _filter<T>(func: Predicate<T>): (arr: T[]) => T[] {
  return (arr: T[]) => arr.filter(func);
}

/** Returns the first element in the array which matches the predicate or else undefined. */
export function find<T>(arr: T[], check: Predicate<T>): T | undefined {
  if (arr.length === 0) {
    return undefined;
  }
  const droppedArr = dropWhile<T>(arr, (input: T) => !check(input));
  return head(droppedArr);
}

/** Returns the index of the first element in an array which matches the predicate or else -1. */
export function findIndex<T>(arr: T[], pred: Predicate<T>): number {
  for (let i = 0; i < arr.length; i++) {
    if (pred(arr[i])) {
      return i;
    }
  }
  return -1;
}

/** Returns the first element of a list, or undefined for empty lists. */
export function head<T>(arr: T[]): T | undefined {
  return arr[0];
}

/** Returns all elements in the array except the first */
export function tail<T>(arr: T[]): T[] {
  return arr.slice(1);
}

/** Returns the last element in a list, or undefined for empty lists */
export function last<T>(arr: T[]): T | undefined {
  return arr.length > 0 ? arr[arr.length - 1] : undefined;
}

/** Returns all elements in the array except the last */
export function init<T>(arr: T[]): T[] {
  return arr.slice(0, arr.length - 1);
}

/** The first item in the array which isn't null or undefined, if there is none, then undefined. */
export function first<T>(arr: T[]): T | undefined {
  return compact(arr)[0];
}

/** True if the array contains no elements */
export function isEmpty(array: {}[]): boolean {
  return array.length === 0;
}

/** True if the array contains at least one element */
export function notEmpty(array: {}[]): boolean {
  return array.length > 0;
}

/** Creates a copy of the array with the elements in the reverse order */
export function reverse<T>(arr: T[]): T[] {
  return arr.slice(0).reverse();
}

/** Returns a new list containing the first num elements of the array */
export function take<T>(arr: T[], num: number): T[] {
  return arr.slice(0, num);
}

export function _take<T>(n: number): (arr: T[]) => T[] {
  return (arr: T[]) => take(arr, n);
}

/** Create a new list by repeating a value num times */
export function repeat<T>(elem: T, num: number): T[] {
  const result: T[] = [];
  for (let i = 0; i < num; i++) {
    result.push(elem);
  }
  return result;
}

/** Returns true if any of the elements in the array satisfy the predicate. */
export function some<T>(arr: T[], pred: Predicate<T>): boolean {
  for (let i = 0; i < arr.length; i++) {
    if (pred(arr[i])) {
      return true;
    }
  }
  return false;
}

/** Returns true if all of the elements in the array satisfy the predicate. */
export function every<T>(arr: T[], pred: Predicate<T>): boolean {
  return !some(arr, (input) => !pred(input));
}

/** Construct arrays out of the elements of the input arrays with matching indicies */
export function zip<T>(arrays: T[][]): T[][] {
  const length = Math.max(...arrays.map((arr) => arr.length));
  const result = [];
  for (let i = 0; i < length; i++) {
    const slice = [];
    for (const arr of arrays) {
      slice.push(arr[i]);
    }
    result.push(slice);
  }
  return result;
}

export type IndexFunc<T> = Func<T, string>;

/** Constructs a dictionary of values (T) where the key is given by the index function */
export function indexBy<T>(arr: T[], func: IndexFunc<T>): Dictionary<T> {
  return dict(
    arr.map((val) => ({
      key: func(val),
      value: val,
    })),
  );
}

/* tslint:disable-next-line:variable-name */
export const _indexBy = <T>(func: IndexFunc<T>) => (arr: T[]) => indexBy(arr, func);

/**
 * Constructs a dictionary of lists of values (T) where the key to group by
 * is given by the index function
 */
export function groupBy<T>(arr: T[], func: IndexFunc<T>): Dictionary<T[]> {
  return dict(
    arr.map((val) => ({
      key: func(val),
      value: [val],
    })),
    (a: Entry<T[]>, b: Entry<T[]>) => {
      // Resolve conflicts by merging the lists
      return {
        key: a.key,
        value: a.value.concat(b.value),
      };
    },
  );
}

/* tslint:disable-next-line:variable-name */
export const _groupBy = <T>(func: IndexFunc<T>) => (arr: T[]) => groupBy(arr, func);

/**
 * Return values:
 *  1 means that a > b
 *  0 means that a = b (only in terms of ordering)
 * -1 means that a < b
 *
 * If used with sort, smaller values will be first in the list
 */
export type ComparisonFunction<T> = (a: T, b: T) => 1 | 0 | -1;

/**
 * Sorts an input array by an optional comparison function
 * @param arr Input array to sort
 * @param comparison Optional comparison function to sort by
 */
export function sort<T>(arr: T[], comparison?: ComparisonFunction<T>): T[] {
  return arr.slice(0).sort(comparison);
}

/* tslint:disable-next-line:variable-name */
export const _sort = <T>(comparison?: ComparisonFunction<T>) => (arr: T[]) => sort(arr, comparison);

/**
 * Creates a comparison function (like you might pass to sort) which uses a derivative value
 * instead of the element itself - useful for sorting objects.
 */
export function _compare<T>(comparisonValue: (input: T) => string | number): ComparisonFunction<T> {
  return (a, b) => {
    const aVal = comparisonValue(a);
    const bVal = comparisonValue(b);
    if (aVal < bVal) {
      return -1;
    }
    if (aVal > bVal) {
      return 1;
    }
    return 0;
  };
}

interface Range<T> {
  lowerBound: T;
  upperBound: T;
}

interface Domain<T> {
  values: (range: Range<T>) => T[];
}

// tslint:disable-next-line:variable-name
const Integers: Domain<number> = {
  values: (within: Range<number>) => {
    const result: number[] = [];
    for (let i = within.lowerBound; i < within.upperBound; i++) {
      result.push(i);
    }
    return result;
  },
};

/**
 * Construct a list of integers from start to end
 * @param start Integer to start at (inclusive)
 * @param end Integer to end at (exclusive)
 */
export function range(start: number, end: number): number[] {
  return Integers.values({ lowerBound: start, upperBound: end });
}

export function partition<T>(values: T[], predicate: Predicate<T>): [T[], T[]] {
  return [values.filter(predicate), values.filter((x) => !predicate(x))];
}
