import * as React from 'react';
import classNames from 'classnames';
import styles from './TextInput.module.scss';

export interface TextInputConfig {
  className?: string;
  autoFocus?: boolean;
  autoComplete?: string;
  placeholder?: string;
  secret?: boolean;
  block?: boolean;
}

interface OwnProps extends TextInputConfig {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  error?: boolean;
  message?: string;
}

export const TextInput = (props: OwnProps) => {
  return (
    <div
      className={classNames(
        styles.TextInput,
        {
          [styles.Block]: props.block,
          [styles.Error]: props.error,
        },
        props.className,
      )}
    >
      <input
        className={styles.InputElement}
        type={props.secret ? 'password' : 'text'}
        onChange={(e) => props.onChange(e.target.value)}
        autoFocus={props.autoFocus}
        autoComplete={props.autoComplete}
        disabled={props.disabled}
        placeholder={props.placeholder}
        value={props.value}
      />
      {props.message ? <div className={styles.InputMessage}>{props.message}</div> : null}
    </div>
  );
};
