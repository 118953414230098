import * as React from 'react';
import classNames from 'classnames';
import styles from './Dropdown.module.scss';
import { find } from '../../lib/list';
import { notMissing, isMissing } from '../../lib/typeGuards';

export interface DropdownOption<T extends string | number | boolean> {
  value: T;
  text: string;
}

export interface DropdownInputConfig<T extends string | number | boolean> {
  className?: string;
  block?: boolean;
  placeholder: string;
  options: DropdownOption<T>[];
}

interface OwnProps<T extends string | number | boolean> extends DropdownInputConfig<T> {
  value: T;
  onChange: (value: T) => void;
  disabled?: boolean;
  error?: boolean;
  message?: string;
}

export const Dropdown = <T extends string | number | boolean>(props: OwnProps<T>) => {
  const node = React.useRef() as React.RefObject<HTMLDivElement>;
  const [expanded, setExpanded] = React.useState(false);

  const activeOption = find(props.options, ({ value }) => value === props.value);

  const handleDOMClick = (e: MouseEvent) => {
    if (!node.current || !node.current.contains(e.target as Node)) {
      setExpanded(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleDOMClick);
    return () => {
      document.removeEventListener('mousedown', handleDOMClick);
    };
  }, []);

  return (
    <div
      ref={node}
      className={classNames(
        styles.Dropdown,
        {
          [styles.NoValue]: isMissing(activeOption),
          [styles.Block]: props.block,
          [styles.Disabled]: props.disabled,
          [styles.Error]: props.error,
          [styles.Expanded]: expanded,
        },
        props.className,
      )}
    >
      <div
        className={styles.DropdownInput}
        onClick={() => !props.disabled && setExpanded(!expanded)}
      >
        <div>{notMissing(activeOption) ? activeOption.text : props.placeholder}</div>
        <i className='far fa-chevron-down' />
      </div>

      {props.message ? <div className={styles.DropdownMessage}>{props.message}</div> : null}

      <ul className={styles.Menu}>
        {props.options.map((option) => (
          <li
            key={option.text}
            className={option.value === props.value ? styles.ActiveItem : ''}
            onClick={() => {
              props.onChange(option.value);
              setExpanded(false);
            }}
          >
            {option.text}
          </li>
        ))}
      </ul>
    </div>
  );
};
